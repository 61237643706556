<template>
    <div class="fl-eq-c">
        <div class="full-page bg-2 of-h">
            <div class="centered-logo text-white">
                <img src="../../assets/img/logo/logo.png" alt="Logo">

            </div>
            </div>
        <div class="full-page of-h">
            <div class="card bs-5 w-30r bg-1" v-if="hideLogin">

             <btn @click="ValidateToken" text="Validate" loading-text="Validating..." size="block"
                         :disabled="loading" :loading="loading" class="text-white"/>
                <div class="card bg-danger mb-2 mt-2" v-if="errorMessage" v-html="errorMessage"></div>
                <ErrorLink v-if="errorMessage" ></ErrorLink>

                </div>

            <div class="card bs-5 w-30r bg-1" v-else>
                <div>
                    <h4 class="text-primary">Login</h4>
                    <p class="text-primary">Login with your credentials</p>
                </div>
                <s-form ref="loginForm">
                    <validated-input icon="fa fa-user" name="Username" label="Username" v-model="model.username"
                                     :disabled="loading" :rules="rules.username" class="text-primary"/>
                    <validated-input icon="fa fa-lock" name="Password" label="Password" type="password"
                                     v-model="model.password" class="text-primary"
                                     :disabled="loading" :rules="rules.password"/>

                    <div class="card bg-danger mb-2" v-if="errorMessage" v-html="errorMessage"></div>

                    <btn @click="submitClicked" text="Login" loading-text="Validating..." size="block"
                         :disabled="loading" :loading="loading" class="text-white"/>
                </s-form>
            </div>
        </div>
    </div>
</template>

<script>
import urls from '@/data/urls';
import { mapActions } from 'vuex';
import axios from 'secure-axios';
import ErrorLink from '../mcq-interview/Interview/ErrorLink';

export default {
    name: 'Login',
    components: { ErrorLink },
    data () {
        return {
            // ################### FOR QUIZ ###############
            hideLogin: false,
            loginUrl: urls.auth.login,
            verifyUrl: urls.auth.verify,
            loading: false,
            token: '',
            model: {},
            errorMessage: '',
            rules: {
                username: {
                    required: true
                },
                password: {
                    required: true
                }
            }
        };
    },
    methods: {
        ...mapActions(['setUser']),
        ...mapActions(['setInterview']),

        async ValidateToken () {
            const that = this;
            that.loading = true;
            try {
                const response = await axios.form(urls.auth.verify, { token: that.token });
                const json = response.data;
                if (!json) {
                    this.loginError();
                } else if (json.error === false) {
                    this.loginClicked(json);
                } else {
                    if (json.message) {
                        console.log('-----------Message-----', json.message);
                        this.errorMessage = json.message;
                        this.$notify(json.message, 'Warning', { type: 'warning' });
                        // this.$notify(json.message, 'warning', { type: 'warning' });
                        // this.$router.push('/mcq-interview/error-link/');
                    }
                }
            } catch (error) {
                console.error(error);
            }
            that.loading = false;
        },
        async submitClicked () {
            const that = this;
            that.loading = true;
            try {
                const response = await axios.form(urls.auth.login, that.model);
                const json = response.data;
                if (!json) {
                    this.loginError();
                } else if (json.error === false) {
                    this.loginClicked(json);
                } else {
                    this.loginError();
                }
            } catch (error) {
                console.error(error);
            }
            that.loading = false;
        },
        formError (json) {
            this.$refs.loginForm.setErrors(json.errors);
            this.$notify('Please fix the issue.', 'Error', {
                type: 'danger'
            });
        },
        loginClicked (response) {
            if (response.user) {
                const redirectUrl = sessionStorage.getItem('redirectPath');
                this.setUser({
                    ...response.user
                });
                if (this.hideLogin) {
                    // const candidate = response.user.candidate;
                    // const interview = response.user.interview;
                    this.setInterview({
                        ...response.user
                    });
                    this.$router.push({ path: '/mcq-interview/landing-page/' });
                } else {
                    this.$router.push(redirectUrl || { path: '/app/' });
                }
            }
            localStorage.clear();
        },
        loginError () {
            this.errorMessage = 'Invalid Credentials';
        }
    },
    mounted () {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has('token')) {
            this.hideLogin = true;
            this.token = urlParams.get('token');
        }
    }
};
</script>
<style scoped>
.full-page {
    height: 100vh;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.centered-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.centered-logo img {
    max-width: 40%;
    max-height: 40%;
}
</style>
