<template>

    <div class="message-box">
      <h4 class="text-primary font-poppins-semibold ml-auto">Invalid or Expired Link</h4>
      <p class="p-centered"><b>The link you followed is either invalid or has expired.<br> Please try the following options:</b></p>
         <div class="text-lg-left ml-5">
      <ul>
        <li>Ensure you have clicked / copied the correct link.</li>
        <li>Request a new link if yours has expired.</li>
        <li>Contact <a href="mailto:midhunvm@xeoscript.com">support</a> for assistance.</li>
      </ul>
             </div>
        <div class="ml-auto">
             <btn text="Refresh Page" @click="refreshPage" class="px-4"></btn>
</div>

  </div>
</template>

<script>
export default {
    name: 'ErrorLink',
    methods: {
        refreshPage () {
            window.location.reload();
        }
    }
};
</script>

<style scoped>

</style>
